import type { Breakpoint, ThemeConfig } from "antd";
import { merge } from "lodash";

import colors from "./colors";
import seedtime from "./seedtime";

export const MOBILE_MAX_SCREEN_SIZE_CODE: Breakpoint = "sm";
export const NON_MOBILE_MIN_SCREEN_SIZE_CODE: Breakpoint = "md";
export const SCREEN_SM_MAX = 767;
export const MOBILE_MAX_SCREEN_SIZE = SCREEN_SM_MAX;
export const NON_MOBILE_MIN_SCREEN_SIZE = MOBILE_MAX_SCREEN_SIZE + 1;

const theme: ThemeConfig = {
  components: {
    Alert: {
      fontSizeIcon: 16,
      borderRadiusLG: 4,
      colorIcon: colors.midGrey,
      colorIconHover: colors.darkGrey
    },
    Badge: {
      colorTextLightSolid: colors.black,
      // Counter background color
      colorError: colors.accent,
      indicatorHeightSM: 18,
      textFontSizeSM: 12
    },
    Breadcrumb: {
      linkHoverColor: colors.linkHover,
      linkColor: colors.primary
    },
    Button: {
      colorTextDisabled: colors.darkGrey,
      colorBgContainerDisabled: colors.lightGrey,
      defaultBorderColor: colors.lightGrey,
      fontSizeLG: 16
    },
    Card: {
      borderRadiusLG: 24,
      colorBorderSecondary: colors.borderSecondary
    },
    Carousel: {
      arrowSize: 48
    },
    Checkbox: {
      colorBgContainer: colors.pageBackground,
      fontSize: 13,
      marginXS: 0
    },
    DatePicker: {
      fontSize: 16,
      paddingXXS: 2
    },
    Dropdown: {
      borderRadiusLG: 4,
      controlPaddingHorizontal: 8,
      fontSize: 13,
      marginXXS: 0,
      paddingXXS: 0
    },
    Input: {
      colorTextPlaceholder: colors.midGrey,
      controlHeightLG: 44,
      paddingBlockLG: 8.5,
      paddingInlineLG: 13
    },
    Modal: {
      headerBg: colors.pageBackground,
      paddingContentHorizontalLG: 0,
      paddingMD: 0
    },
    Popover: {
      paddingSM: 8,
      fontSize: 13
    },
    Progress: { defaultColor: colors.primary },
    Radio: {
      borderRadius: 10,
      buttonSolidCheckedBg: colors.primary,
      buttonSolidCheckedColor: colors.white,
      colorBgContainer: "transparent",
      colorText: colors.midGrey,
      controlHeight: 34
    },
    Select: {
      borderRadiusSM: 0,
      controlHeight: 44,
      fontSize: 16,
      multipleItemBorderColor: colors.lightGrey,
      optionActiveBg: "#f7f9f1",
      optionPadding: 10,
      paddingXXS: 0
    },
    Switch: {
      innerMaxMargin: 33,
      innerMinMargin: 9,
      innerMinMarginSM: 9,
      innerMaxMarginSM: 33,
      trackHeight: 30,
      trackHeightSM: 22,
      handleSize: 26,
      handleSizeSM: 18
    },
    Tabs: {
      itemSelectedColor: "#332b25"
    },
    Tooltip: {
      fontSize: 13
    }
  },
  token: {
    borderRadius: 8,
    colorIcon: colors.primary,
    colorLink: colors.primary,
    colorPrimary: colors.primary,
    colorError: colors.error,
    colorSuccessText: colors.primary,
    colorTextSecondary: colors.midGrey,
    fontFamily: seedtime["text-font"],
    fontSize: 16,
    screenSMMax: SCREEN_SM_MAX
  }
};

export const mobileTheme = merge<ThemeConfig, ThemeConfig>(theme, {
  components: {
    Button: {
      fontSizeLG: 14
    },
    Select: {
      fontSize: 14
    }
  },
  token: { fontSize: 14 }
});

export const gardenPlanTheme = merge<ThemeConfig, ThemeConfig>(theme, {
  components: {
    Form: { fontSize: 13, itemMarginBottom: 12, verticalLabelPadding: 0 },
    Select: {
      controlHeight: 36,
      fontSize: 14,
      optionLineHeight: "20px"
    },
    Switch: {
      innerMinMarginSM: 5,
      innerMaxMarginSM: 20,
      trackHeightSM: 16,
      handleSizeSM: 12
    }
  }
});

export default theme;
