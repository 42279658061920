import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import { Modal } from "tailwindcss-stimulus-components";

const application = Application.start();

// Stimulus has removed webpack integration
// https://github.com/hotwired/stimulus/pull/444
const context = require.context("controllers", true, /_controller\.js$/);
application.load(definitionsFromContext(context));

application.register("modal", Modal);
